import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

// import WorkoutTimerIcon from "../images/WorkoutTimerIcon.png"

const WebDev = () => (
    <Layout>
        <Seo title="Web Development" />
        <div className="main">
            <h1>Web Development</h1>
            <section className="contents">
                <h2>佐々畜産</h2>
                {/* <img className="width_30vw padding-top_10vh padding-bottom_10vh" src={WorkoutTimerIcon} alt=""/> */}
                {/* <h3></h3> */}
            </section>
        </div>
    </Layout>
  )
  
  export default WebDev